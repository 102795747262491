import "parsleyjs/src/parsley.js";
import "parsleyjs/src/i18n/fr.js";
import "parsleyjs/src/i18n/es.js";
import "parsleyjs/src/i18n/it.js";
import "parsleyjs/src/i18n/de.js";
import "jquery-ui/dist/jquery-ui.js";
import "@hotwired/turbo-rails";
// import "jquery-smooth-scroll/jquery.smooth-scroll.js";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "moment/src/moment.js";
import "moment/src/locale/de.js";
import "moment/src/locale/es.js";
import "moment/src/locale/fr.js";
import "moment/src/locale/it.js";
// import "flexslider/jquery.flexslider.js";
import "@vendor/jquery.rwdImageMaps.js";
import "@vendor/jquery.capsChecker.js";
import "@vendor/handlebars-v1.2.0";
import "bootstrap-select";

import "./custom.js";
import "./events.js";
import "./tutors/custom.js";
import "./tableau/custom.js";
import "./modals.js";

$(function () {
  window.Parsley.addValidator("checkOverAge", {
    requirementType: "string",
    validateString: function (value, requirements) {
      var classId = requirements.split("-")[0];
      var age = requirements.split("-")[1];
      var day = $("." + classId + "-day").val(),
        month = $("." + classId + "-month").val(),
        year = $("." + classId + "-year").val();

      if (isNumeric(day) === false || isNumeric(month) === false || isNumeric(year) === false) {
        return true;
      }

      day = zeroPad(day, 2);
      month = zeroPad(month, 2);
      year = zeroPad(year, 4);

      var inputDate = moment(year + "-" + month + "-" + day, "YYYY-MM-DD");
      var birthDate = moment().subtract(age, "years");

      if (inputDate.isValid() && inputDate <= birthDate) {
        return true;
      } else {
        return false;
      }
    },
  });

  window.Parsley.addValidator("checkUnderAge", {
    requirementType: "string",
    validateString: function (value, requirements) {
      var classId = requirements.split("-")[0];
      var age = requirements.split("-")[1];
      var day = $("." + classId + "-day").val(),
        month = $("." + classId + "-month").val(),
        year = $("." + classId + "-year").val();

      if (isNumeric(day) === false || isNumeric(month) === false || isNumeric(year) === false) {
        return true;
      }

      day = zeroPad(day, 2);
      month = zeroPad(month, 2);
      year = zeroPad(year, 4);

      var inputDate = moment(year + "-" + month + "-" + day, "YYYY-MM-DD");
      var birthDate = moment().subtract(age, "years");

      if (inputDate.isValid() && inputDate > birthDate) {
        return true;
      } else {
        return false;
      }
    },
  });

  window.Parsley.addValidator('rejectPattern', {
    requirementType: 'string',
    validateString: function(value, requirement) {
      // check that the email value does not contain http or // via regex
      var regex = new RegExp(requirement);
      return !regex.test(value);
    },
  });

  window.Parsley.addValidator("rejectEmoji", {
    // requirementType: "string",
    validateString: function (value) {
      var regex = new RegExp(
        /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/gu
      );
      return !regex.test(value);
    },
  });

  window.Parsley.addAsyncValidator(
    "emailUnique",
    function (xhr) {
      // Ritorna true anche se la richiesta non funziona per far
      // comunque andare avanti l'utente.
      // La validazione della mail c'è comunque alla fine del form.
      return xhr.status !== 200 || !xhr.responseJSON.found;
    },
    "/api/v5/users/email/check?email={value}"
  );

  if (!localStorage.getItem("adomaniaClosed")) {
    $("#adomania-popup").modal();
    $("#adomania-popup").on("hidden.bs.modal", function () {
      localStorage.setItem("adomaniaClosed", true);
    });
  }

  // Nel caso in cui la data di nascita non viene passata dal provider
  // oauth, non si può avanzare automaticamente il primo step
  if (isOauth() && isBirthdaySet()) {
    $("#new_user").hide();
    $("#oauth-spinner").show();
  }

  $('[data-toggle="tooltip"]').tooltip();

  if (/Android|webOS|BlackBerry/i.test(navigator.userAgent)) {
    $(".selectpicker").selectpicker("mobile");
  }

  if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    $(".selectpicker").removeClass("selectpicker");
  }

  // chrome workaround, altrimenti non onora 'autocomplete='off''
  $("#city_id").attr("autocomplete", "random");
  $("#city").attr("autocomplete", "none");

  $("#countainer_schools").on("autocompletechange", ".dynamic_section .user-field .schools_city", function () {
    var parent = $(this).closest(".dynamic_section");
    var sel = parent.find("select.schools_select");
    var city = $(this).data();
    var course = parent.find("select.courses_select");
    course.find("option").remove();
    fill_schools($(sel), city.id);
  });

  $(function () {
    setTimeout(function () {
      $(".alert").slideUp(700, "linear", function () {
        $("header.welcome-page").removeClass("lets-display-alert");
      });
    }, 7000);
  });

  (function ($) {
    $.each(["show", "hide", "fadeOut", "fadeIn", "slideUp"], function (i, ev) {
      var el = $.fn[ev];
      $.fn[ev] = function () {
        var result = el.apply(this, arguments);
        result.promise().done(function () {
          this.trigger(ev, [result]);
        });
        return result;
      };
    });
  })(jQuery);

  $(".alert").on("show", function () {
    $("header.welcome-page").addClass("lets-display-alert");
  });

  $("select[name='filter_for_publisher_select']").on("change", function () {
    var url = $(this).val();
    if (url) {
      // require a URL
      window.location = url; // redirect
    }
    return false;
  });

  $("header.user-role-select-form .signup-steps input:submit[name!='commit']").on("click", function (event) {
    event.preventDefault();
    manageStepChange(this);
  });

  $("header.user-role-select-form .signup-steps input:submit[name='commit']").on("click", function (event) {
    event.preventDefault();
    $(this).closest("form").submit();
  });

  $("header.user-role-select-form .flat-box-steps-circle div").on("click", function () {
    manageStepCircles(this);
  });

  var userLang = navigator.language || navigator.userLanguage;
  userLang = userLang.substring(0, 2).toLowerCase();

  if (!["it", "en", "fr", "de", "es"].includes(userLang)) {
    userLang = "en";
  }
  window.Parsley.setLocale(userLang);

  if ($("#new_user").length != 0) {
    $("#new_user").parsley();
  }

  // New parent link form
  $("#new_connect_existing_account_form").parsley();

  $("#connect_existing_account_form_email").on("change keyup", function () {
    manageNewParentLinkDisableBtn(this);
  });

  $("header.new_user_relation_form .signup-steps input:submit[name='commit']").on("click", function (event) {
    event.preventDefault();
    $(this).closest("form").submit();
  });
  // End New parent link form

  $("header.user-role-select-form input").on("change keyup", function () {
    manageInputChange(this);
  });

  $(".step-1-adult input").on("change keyup", function () {
    manageDisableBtn(this, 1);
  });

  $(".step-1-adult select").on("change keyup", function () {
    manageDisableBtn(this, 1);
  });

  $(".step-2-adult input").on("change keyup", function () {
    manageDisableBtn(this, 2);
  });

  $(".step-1-parent input").on("change keyup", function () {
    manageDisableBtn(this, 1);
  });

  $(".step-2-parent input").on("change keyup", function () {
    manageDisableBtn(this, 2);
  });

  $(".step-1-parent select").on("change keyup", function () {
    if (!isDateFullyEntered()) return;

    $(".userbday-day").parsley().validate();
    $(".userbday-month").parsley().validate();
    $(".userbday-year").parsley().validate();
    manageDisableBtn(this, 1);
  });

  $(".step-1-teacher input").on("change keyup", function () {
    manageDisableBtn(this, 1);
  });

  $(".step-1-adult select").on("change keyup", function () {
    if (!isDateFullyEntered()) return;

    $(".userbday-day").parsley().validate();
    $(".userbday-month").parsley().validate();
    $(".userbday-year").parsley().validate();
    manageDisableBtn(this, 1);
  });

  $(".step-2-teacher input").on("change keyup", function () {
    manageDisableBtn(this, 2);
  });

  $(".step-1-teacher select").on("change keyup", function () {
    if (!isDateFullyEntered()) return;

    $(".userbday-day").parsley().validate();
    $(".userbday-month").parsley().validate();
    $(".userbday-year").parsley().validate();
    manageDisableBtn(this, 1);
  });

  $(".step-2-teacher select").on("change keyup", function () {
    manageDisableBtn(this, 2);
  });

  $(".step-1-young input").on("change keyup", function () {
    manageDisableBtn(this, 1);
  });

  $(".step-1-young select").on("change keyup", function () {
    if (!isDateFullyEntered()) return;

    $(".userbday-day").parsley().validate();
    $(".userbday-month").parsley().validate();
    $(".userbday-year").parsley().validate();
    manageDisableBtn(this, 1);
  });

  $(".step-2-young input").on("change keyup", function () {
    manageDisableBtn(this, 2);
  });

  $(".step-2-young select").on("change keyup", function () {
    manageDisableBtn(this, 2);
  });

  $(".step-3-young input").on("change keyup", function () {
    manageDisableBtn(this, 3);
  });

  $(".step-3-teacher input").on("change keyup", function () {
    manageDisableBtn(this, 3);
  });

  $(".required-by-fake-email").on("change keyup", function () {
    $("#new_user")
      .parsley()
      .whenValid({ group: "fake_email" })
      .then(function () {
        $("#checkbox-form-fake-email").removeAttr("disabled"); // firefox compatibility
      })
      .catch(function () {
        $("#checkbox-form-fake-email").prop("disabled", true);
      });
  });

  var region_sel = $("#region_id");
  if(region_sel.length > 0) {
    fill_regions(region_sel);
  }

  $(".ui-autocomplete").on("click", function (event) {
    var sel = $("#school_code");
    var city = $("#city_id").data();
    sel.find("option").remove();
    fill_schools(sel, city.id);
  });

  // required to avoid double-click on submit button
  $(".btn-signup-flow-last").one("click", function (event) {
    $(this).prop("disabled", true);
  });
  $(".btn-signup-flow-last").dblclick(function (e) {
    e.preventDefault();
  });

  $("#new_bsmart_activation_form").submit(function (event) {
    $("#new_bsmart_activation_form .btn.btn-bsmart").prop("disabled", true);
  });
  $(".btn.btn-default.btn-eli.btn-bsmart").dblclick(function (e) {
    e.preventDefault();
  });

  $("#header.user-role-select-form select#nation").on("change", function () {
    if ($(this).val() == "NP") {
      $(".school-inputs").hide();
      $(".user-field.classroom-name").hide();
      $(".user-field.school-name").hide();
      $("#city_id").prop("required", false);
      $("#city_id").attr("data-parsley-required", "false");
      $("#region_id").prop("required", false);
      $("#region_id").attr("data-parsley-required", "false");
      $("#school_code").prop("required", false);
      $("#school_code").attr("data-parsley-required", "false");
      $("#classroom").prop("required", false);
      $("#classroom").attr("data-parsley-required", "false");
      $("#classroom").val("");
      $("#city_id").val("");
      $("input[name='school_type']").prop("required", false);
      $("input[name='school_type']").attr("data-parsley-required", "false");
      $('input[name="school_type"]').prop("checked", false);
      $("#school_code").empty();
      $("#nation").parsley().validate();
      manageDisableBtn(this, 1);
    } else {
      $(".school-inputs").not(".school-type", ".school-details").show();
      $("#city_id").prop("required", true);
      $("#city_id").attr("data-parsley-required", "true");
      $("#region_id").prop("required", true);
      $("#region_id").attr("data-parsley-required", "true");
      $("input[name='school_type']").prop("required", true);
      $("input[name='school_type']").attr("data-parsley-required", "true");
      $("#school_code").prop("required", true);
      $("#school_code").attr("data-parsley-required", "true");
      $("#classroom").prop("required", true);
      $("#classroom").attr("data-parsley-required", "true");
    }
  });

  $("#school_code").on("change", function () {
    if ($(this).val() !== "") {
      fill_courses($("#course"), $(this).val());
      $(".course-name").show();
    } else {
      $(".course-name").hide();
    }
  });

  $("#user_schools_field").on("change", function () {
    if ($(this).val() !== "") {
      fill_courses($("#course"), $(this).val());
    }
  });

  $("#region_id").on("change", function () {
    if ($(this).val()) {
      $("#city_id").val("");
      $("#city_id").autocomplete("option", "source", "/school_data/cities_list_by_name?region_id=" + $(this).val());
    }
  });

  $("#checkbox-form-fake-email").on("click", function () {
    if ($(this)[0].checked) {
      var name = $("#user_name")[0].value;
      var surname = $("#user_surname")[0].value;
      var birthday = $(".userbday-year")[0].value + "/" + $(".userbday-month")[0].value + "/" + $(".userbday-day")[0].value;
      generate_fake_email($("#user_email")[0], $("#email_confirmation")[0], name, surname, birthday);
      disable_email_fields();
    } else {
      enable_email_fields();
    }
  });

  $("input[name='school_type']").on("click", function () {
    var radio_value = $("input[name='school_type']:checked").val();
    if (radio_value == "public") {
      enable_school_fields();
    } else {
      disable_school_fields(radio_value);
    }
  });

  // Required to trigger validation when step 1 is
  // completely autofilled with oauth data
  $("#user_name").trigger("change");

  // Is `n` a number or numeric string (eg "3")?
  window.isNumeric = function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };

  // Pad `n` with `z` (or `0` by default) to `width`.
  window.zeroPad = function zeroPad(n, width, z) {
    z = z || "0";
    n = n + "";
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  };

  // Return true if day, month and year is entered and
  // the date is ready to be validated
  // otherwise return false
  // This is needed to prevent starting validation
  // when the user select just the day
  window.isDateFullyEntered = function isDateFullyEntered() {
    var day = $(".userbday-day").val();
    var month = $(".userbday-month").val();
    var year = $(".userbday-year").val();

    if (isNumeric(day) === false || isNumeric(month) === false || isNumeric(year) === false) {
      return false;
    }

    return true;
  };

  if (isOauth()) {
    // SPIEGONE PARTE 4 - 1/10/2020
    // Non perdere le puntate precedenti, su un cerca vicino a te!
    // al momento abbiamo 3 flussi di registrazione: student, teacher e parent.
    // Quest'ultimo prevede solo anagrafica e TOS (niente scuola), quindi saltiamo
    // tutto il pezzo dello spiegone parte 1!
    var flow = $("#user_flow").val();

    if (flow === "parent") {
      advanceStep();
    }
  }
});

// Just create a scope
(function () {
  $(function checkCapsLock() {
    var inputWrappers = $(".check-caps-lock").each(function (i, wrap) {
      var el = null;
      if (wrap.tagName == "INPUT") {
        el = wrap;
        wrap = $(el).closest(".form-group");
      } else {
        el = $(wrap).find("input");
      }
      if (!el) {
        // No input element found, skip
        return true;
      }

      $(el).on("keyup", function (evt) {
        // some events has not `getModifierState`
        if (!evt.originalEvent.getModifierState) return true;
        var capsLock = evt.originalEvent.getModifierState("CapsLock");
        $(this).trigger("hasCapsLockEnable", { status: capsLock });
        if (wrap) {
          $(wrap).find("small.caps-lock-message")[capsLock ? "removeClass" : "addClass"]("hide");
        }
      });
    });
  });

  $(function showHidePassword() {
    var inputWrappers = $(".show-hide-password").each(function (i, wrap) {
      var $self = $(this);
      var handler = $(wrap).find(".label-for-show-password");
      handler.on("click", function (event) {
        handler.toggleClass("open");
        let input = $self.find("input[type]");
        if (input.length) {
          input[0].type = input[0].type == "password" ? "text" : "password";
        } else {
          console.warn("cannot find input[type]");
        }
      });
    });
  });
})();

window.isOauth = function isOauth() {
  // SPIEGONE PARTE 3 - 1/10/2020
  // Hai perso le puntate precedenti? cerca è tuo amico!
  // Per evitare di far saltare step quando non siamo in oauth, usiamo il valore
  // di questo input, che al momento viene valorizzato sempre con l'uid della
  // richiesta. OCCHIO!!
  var uid = $("#user_identity_uid").val();
  return uid !== undefined && uid !== null && uid !== "";
};

window.isStepValid = function isStepValid(step) {
  return $("#new_user")
    .parsley({ excluded: "select:hidden" })
    .whenValid({ group: "step_" + step });
};

window.changeStep = function changeStep(step, flow) {
  // SPIEGONE PARTE 2 - 1/10/2020
  // Per le puntate precedenti usa il cerca!
  // Altro spiegone: per come sono fatti i flussi di registrazione oggi, i campi
  // sono tutti dipendenti uno con l'altro e vengono mostrati in sequenza._
  // Questo metodo serve appositamente per gestire i casi "anomali":
  // - Se siamo in oauth e abbiamo i dati della scuola, dobbiamo abilitare a manina
  //   il bottone dello step 2 (se no parsley non lo fa) ed evitare di fare tutto
  //   il teatrino dela mostra/nascondi condizionale. L'utente si trova con tutto
  //   popolato e via.
  // -  Stessa cosa se saltiamo a piè pari lo step 2 (è tutto compilato da oauth
  //    tranne le tos) - se non abilitiamo il pulsante del bottone 2 e l'utente
  //    fa back, rimane incastrato.
  // "Not the code we want, but the want we need." - Dark bweb rises, 2020

  if (step === 2) {
    var prefilled = $("#user_prefilled_region").val();
    var oauth = isOauth();

    if (oauth && prefilled) {
      manageDisableBtn($("#nation"), 2);
    } else {
      $(".school-inputs").not(".school-geo-info").hide();
    }
  }

  if (step === 3) {
    manageDisableBtn($("#nation"), 2);
  }

  $(".signup-steps").hide();
  $(".step-" + step + "-" + flow).show();
  $("html, body").animate({ scrollTop: 0 }, "slow");
};

window.getTargetStep = function getTargetStep(callback) {
  isStepValid(1)
    .then(function () {
      isStepValid(2)
        .then(function () {
          isStepValid(3)
            .then(function () {
              callback(null);
            })
            .catch(function () {
              callback(3);
            });
        })
        .catch(function () {
          callback(2);
        });
    })
    .catch(function () {
      callback(1);
    });
};

window.advanceStep = function advanceStep() {
  getTargetStep(function (step) {
    var flow = $("#user_flow").val();
    if (step) {
      changeStep(step, flow);
      $("#oauth-spinner").hide();
      $("#new_user").show();
    }
  });
};

var getCurrentStep = (window.getCurrentStep = function ($this) {
  var classesParent = $($this).closest(".signup-steps").attr("class").split(" ");
  var curStep = classesParent[2].split("-")[1];
  return curStep;
});

var manageDisableBtn = (window.manageDisableBtn = function ($this, step) {
  var btn = $($this).closest(".signup-steps").find("input[type='submit']");
  $("#new_user")
    .parsley({ excluded: "select:hidden" })
    .whenValid({ group: "step_" + step })
    .then(function () {
      $(btn).removeAttr("disabled"); // firefox compatibility
    })
    .catch(function () {
      $(btn).prop("disabled", true);
    });
});

// submit button for parent link form
var manageNewParentLinkDisableBtn = (window.manageNewParentLinkDisableBtn = function ($this) {
  var btn = $("input[type='submit']")[0];
  if ($("#connect_existing_account_form_email").parsley().isValid() === true) {
    $(btn).removeAttr("disabled");
  } else {
    $(btn).prop("disabled", true);
  }
});

var onPrivaciesChange = (window.onPrivaciesChange = function ($this, step) {
  if ($($this).is(":checked")) {
    $($this).attr("value", "1");
    $($this).attr("checked", true);
  } else {
    $($this).attr("value", "");
    $($this).attr("checked", null);
  }
  manageDisableBtn($this, step);
});

var dateSeparator = "/";

var correctCaptcha = (window.correctCaptcha = function (response) {
  $("#recaptcha_status").val("1");
  var classesParent = $(".g-recaptcha").closest(".signup-steps").attr("class").split(" ");
  var curStep = classesParent[2].split("-")[1];
  manageDisableBtn(".g-recaptcha", curStep);
});

window.captchaSubmit = function captchaSubmit(token) {
  document.getElementById("captcha_response").value = token;
  document.getElementById("new_user").submit();
};

var manageStepCircles = (window.manageStepCircles = function ($this) {
  var currentIndex = $($this).siblings(".active").attr("class").split(" ")[0].split("-")[1];
  var selected = $($this).attr("class").split(" ")[0];
  var selectedIndex = selected.split("-")[1];
  if (selectedIndex * 1 < currentIndex * 1) {
    $(".flat-box.signup-steps").hide();
    $(".flat-box-header.signup-steps").hide();
    $(".flat-box-steps-circle").hide();
    $(".flat-box.signup-steps." + selected).show();
    $(".flat-box-header.signup-steps." + selected).show();
    $(".flat-box-steps-circle." + selected).show();
    $("html, body").animate({ scrollTop: 0 }, "slow");
  }
});

var manageInputChange = (window.manageInputChange = function ($this) {
  var step = getCurrentStep($this);
  if ($($this).is(":checkbox")) {
    if ($($this).attr("id") == "checkbox-form-tos_agreement") {
      $(".privacies input:checkbox").prop("checked", $($this).is(":checked"));
      $(".privacies input:checkbox").each(function () {
        onPrivaciesChange(this);
      });
    } else if ($($this).data("privacies") !== undefined) {
      $("#checkbox-form-tos_agreement").prop("checked", false);
      $("#checkbox-form-tos_agreement").attr("value", "");
      onPrivaciesChange($this, step);
    }
  }
});

var manageStepChange = (window.manageStepChange = function ($this) {
  var classesParent = $($this).closest(".signup-steps").attr("class").split(" ");
  var curStep = classesParent[2].split("-")[1];
  var curFlow = classesParent[2].split("-")[2];
  var nextStep = curStep * 1 + 1;

  changeStep(nextStep, curFlow);
});

var gobackSignupFlow = (window.gobackSignupFlow = function ($this) {
  // event.preventDefault();
  var classesParent = $($this).closest(".signup-steps").attr("class").split(" ");
  var curStep = classesParent[2].split("-")[1];
  var curFlow = classesParent[2].split("-")[2];
  var nextStep = curStep * 1 - 1;
  $(".signup-steps").hide();
  $(".step-" + nextStep + "-" + curFlow).show();
  $("html, body").animate({ scrollTop: 0 }, "slow");
});

// SPIEGONE PARTE 5 - 15/01/2021
// Nel caso in cui non viene pre-compilata la parte
// birthday, non si può skippare la prima parte della registazione
// nemmeno in oAuth
window.isBirthdaySet = function isBirthdaySet() {
  var day = $(".userbday-day").val();
  var month = $(".userbday-month").val();
  var year = $(".userbday-year").val();

  return day && month && year;
};

window.fill_regions = function fill_regions(obj) {
  obj.find("option").remove();
  obj.append("<option value=''> </option>");

  $.ajax({
    url: "/school_data/regions_list",
    dataType: "json",
    success: function (result) {
      $.each(result, function (key, value) {
        obj.append("<option value=" + value.id + ">" + value.region_name + "</option>");
      });
      // SPIEGONE PARTE 1 - 1/10/2020
      // Spiegone unico valido per tutto questo discorso: al 1/10/2020 abbiamo
      // deciso di implementare l'avanzamento automatico negli step di registrazione,
      // che al momento sono:
      // 1 - Info anagrafiche (data di nascita, mail e generalità);
      // 2 - Scuola e classe;
      // 3 - TOS
      //
      // Abbiamo 3 possibili flussi di registrazione: student, teacher e parent.
      // I primi due hanno tutti e 3 gli step (il teacher non deve però impostare
      // la classe), mentre l'ultimo salta lo step intermedio.
      //
      // Visto che le chiamate che popolano i vari elenchi di regioni, città,
      // scuole e classi sono tutte asincrone, le facciamo una volta sola (e solo
      // in caso di oauth) qui. Finchè non completano l'utente vede uno spinner.
      if (isOauth()) {
        var prefilled = $("#user_prefilled_region").val();
        if (prefilled) {
          $(obj).val(prefilled);
          var city = $("#user_prefilled_city").val();
          var cityId = $("#user_prefilled_city_id").val();
          $("#city_id").val(city);
          $(".school-inputs").show();
          $("#radio-form-school-type-public").prop("checked", true);
          var school = $("#school_code");
          var schoolCode = school.val();
          var city = $("#city_id").data();
          var course = $("#course");
          fill_schools($(school), cityId, schoolCode);
          fill_courses($(course), schoolCode, course.val());
        }

        // Nel caso in cui la data di nascita non viene passata dal provider
        // oauth, non si può avanzare automaticamente il primo step
        var birthdaySet = isBirthdaySet();

        if (birthdaySet) {
          advanceStep();
        }
      }
    },
  });
};

window.fill_schools = function fill_schools(obj, city, selected) {
  obj.find("option").remove();
  obj.append("<option value=''> </option>");
  if (city) {
    $.ajax({
      url: "/school_data/schools_list_by_city_id?city_id=" + city,
      dataType: "json",
      success: function (result) {
        $.each(result, function (key, value) {
          var typeSafe = value.school_type.replace(/"/gi, "");
          var nameSafe = value.name.replace(/"/gi, "");
          var addressSafe = value.address.replace(/"/gi, "");
          var type = "<span class='school-item-title-label'>" + typeSafe + "</span>";
          var name = "<span class='school-item-title-label'>" + nameSafe + "</span>";
          var address = "<span class='school-item-address-label'>" + addressSafe + "</span>";
          var label = type + "<br>" + name + "<br>" + address;
          obj.append("<option value=" + value.school_code + ' data-content="' + label + '"> ' + typeSafe + " | " + nameSafe + " | " + addressSafe + "</option>");
        });
        if (selected) {
          obj.val(selected);
        }
        $(".selectpicker").selectpicker("refresh");
      },
    });
  }
};

window.fill_school_email = function fill_school_email() {
  var email = $("#school_email")[0].value;
  console.log(email);
  $("#mail_from_school")[0].value = email;
};

window.fill_courses = function fill_courses(obj, school_code, selected) {
  obj.find("option").remove();
  obj.append("<option value=''> </option>");

  $.ajax({
    url: "/school_data/courses_by_school_code?school_code=" + school_code,
    dataType: "json",
    success: function (result) {
      $.each(result, function (key, value) {
        obj.append("<option value=" + value.value + ">" + value.text + "</option>");
      });
      if (selected) {
        obj.val(selected);
      }
    },
  });
};

window.generate_fake_email = function generate_fake_email(email_obj, fake_email_obj, name, surname, birthday) {
  $.ajax({
    url: "/profile/generate_fake_email",
    type: "POST",
    data: { name: name, surname: surname, birthday: birthday },
    dataType: "json",
    success: function (result) {
      $.each(result, function (key, value) {
        email_obj.value = value;
        fake_email_obj.value = value;
      });
    },
  });
};

window.disable_email_fields = function disable_email_fields() {
  $("#user_email").prop("readonly", true);
  $("label[for=email_confirmation], #email_confirmation").hide();
};

window.enable_email_fields = function enable_email_fields() {
  $("#user_email")[0].value = "";
  $("#user_email").prop("readonly", false);
  $("#email_confirmation")[0].value = "";
  $("label[for=email_confirmation], #email_confirmation").show();
};

window.disable_school_fields = function disable_school_fields(private_institute = false) {
  var school = $("#school_code");
  var school_code = private_institute ? school.data("private-institutes-school-code") : school.data("universities-school-code");
  school.find("option").remove();
  school.append("<option value='" + school_code + "'>Other</option>");
  school.value = school_code;

  var classroom = $("#classroom");
  classroom.find("option").remove();
  classroom.append("<option value='other'>Other</option>");
  classroom.value = "other";

  $(".school-details").hide();
};

window.enable_school_fields = function enable_school_fields() {
  var school = $("#school_code");
  var city = $("#city_id").data();
  fill_schools(school, city.id);

  var classroom = $("#classroom");
  classroom.find("option").remove();
  classroom.val("");

  $(".user-field.classroom-name").hide();
  $(".school-details").show();
  $(".user-field.school-name").show();
};

window.fillProfileSchoolDets = function fillProfileSchoolDets() {
  $("#countainer_schools .dynamic_section").each(function (i, container) {
    container = $(container);
    var school = container.find("select.schools_select");
    var schoolCode = school.val();
    var city = container.find("input.schools_city").data();
    var course = container.find("select.courses_select");
    fill_schools($(school), city.id, schoolCode);
    fill_courses($(course), schoolCode, course.val());
  });
};
