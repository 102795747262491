const CLASSES = {
  NAVBAR_LIFTED: "nav-bar--lifted",
};

class Header {
  constructor({ selector } = {}) {
    this.selector = selector;
    this.bindElements();
    this.setupListeners();
  }

  start() {
    this.toggleHeaderLifted();
  }

  bindElements() {
    this.navbar = document.querySelector(this.selector);
  }

  setupListeners() {
    window.addEventListener("scroll", () => this.handleWindowScroll());
  }

  handleWindowScroll() {
    this.toggleHeaderLifted();
  }

  toggleHeaderLifted() {
    if (window.scrollY > 0) {
      this.navbar.classList.add(CLASSES.NAVBAR_LIFTED);
    } else {
      this.navbar.classList.remove(CLASSES.NAVBAR_LIFTED);
    }
  }
}

export default Header;
